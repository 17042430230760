import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAsset(title: { eq: "New-Hue-Logo" }) {
          fluid {
            src
          }
        }
      }
    `}
    render={data => (
      <header>
        <div id="nav">
          <ul>
            <li>
              <Link to="/" activestyle={{ color: "#000" }}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" activestyle={{ color: "#000" }}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/services" activestyle={{ color: "#000" }}>
                Our Services
              </Link>
            </li>
            <li>
              <Link to="/portfolio" activestyle={{ color: "#000" }}>
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="/philosophy" activestyle={{ color: "#000" }}>
                Philosophy
              </Link>
            </li>
            <li>
              <Link to="/awards" activestyle={{ color: "#000" }}>
                Awards
              </Link>
            </li>
          </ul>
        </div>
        <hr />
        <div id="logo">
          <img
            src={data.contentfulAsset.fluid.src}
            alt="New Hue Painting Logo"
          />
        </div>
      </header>
    )}
  />
);
