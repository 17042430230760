import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

class HomePage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const image_url = data.contentfulAsset.file.url;

    return (
      <Layout location="./" title={siteTitle}>
        <SEO title="Home" keywords={[`painting`, `historical`]} />
        <div className="left">
          <p>
            Welcome to New Hue Painting, Michigan’s finest full-service painting
            and finishing company. Specializing in restoring and painting homes
            of distinction in Southeast Michigan since 1990.
          </p>
          <p>
            Our mission is to provide an unmatched and exceptional painting
            experience for all of our clients with respect to their home and
            occupants.
          </p>
          <blockquote>
            “The whole world as we experience it visually, comes to us through
            the mystic realm of color.”
            <br />
            Hans Hoffman
          </blockquote>
        </div>
        <div className="right">
          <img src={image_url} alt="A beautiful dresser" />
        </div>
      </Layout>
    );
  }
}

export default HomePage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAsset(title: { eq: "home-content" }) {
      file {
        url
      }
    }
  }
`;
