import React from "react";
import { rhythm } from "../utils/typography";
import Header from "./header";
import Facebook from "../../static/icons/facebook.svg";
import LinkedIn from "../../static/icons/linkedin.svg";

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div
        id="main-container"
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(25),
          padding: `${rhythm(1 / 4)} ${rhythm(1)}`
        }}
      >
        <Header />
        <main>{children}</main>
        <footer>
          <div className="footer-container">
            <p>New Hue Painting | Sean R. Autrey</p>
            <div id="social-icons">
              <a
                href="https://www.facebook.com/New-Hue-Painting-173558012731246/"
                target="blank"
              >
                <Facebook />
              </a>
              <a
                href="https://www.linkedin.com/in/sean-autrey-45baa132"
                target="blank"
              >
                <LinkedIn />
              </a>
            </div>
            <p>
              w <a href="tel:5174238296">517.423.8296</a> | c{" "}
              <a href="tel:7346457846">734.645.7846</a>
            </p>
            <p>
              <a href="mailto:info@NewHuePainting.com">
                info@NewHuePainting.com
              </a>
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default Layout;
